<template>
  <div>
    <OrderStatus
      :order-no="orderNo"
      status-text="等待审核"
      :time="createTime"
    >
      <template slot="operate">
        <!-- <a-button
          class="btn"
          type="primary"
          @click="getProject"
        >
          立即投保
        </a-button> -->
      </template>
    </OrderStatus>
    <!-- 弹窗 -->
    <DocumentDialog
      class="invitationLetterDialog"
      title="投标保函申请邀约函"
      ok-text="我已阅读投保须知并同意"
      cancel-text="不同意，取消投保"
      :is-show-know-tips="true"
      :visible="invitationLetterDialog.visible"
      @ok="handleConfirm"
      @cancel="()=>{invitationLetterDialog.visible=false}"
    />
  </div>
</template>

<script>
import DocumentDialog from '@/components/DocumentDialog';
import OrderStatus from '@/components/OrderStatus';
// import { Button } from 'ant-design-vue';

export default {
  name: 'OrderPageStatusCodeEqual1',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderNo: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    createTime: {
      type: Number,
    },
  },
  components: {
    DocumentDialog,
    OrderStatus,
    // aButton: Button,
  },
  data() {
    return {
      invitationLetterDialog: {
        visible: false,
      },
    };
  },

  methods: {
    getProject() {
      this.$router.push({ name: 'Project' });
    },
    handleConfirm({ isKnow }) {
      if (!isKnow) {
        this.$message.warn('请勾选我已阅读并同意《投标保函申请邀约函》');
        return;
      }
      const insureUrl = `${process.env.VUE_APP_INSURE_HREF}/#/tenderNotice?orderNo=${this.orderNo}`;
      window.open(insureUrl);
      this.$message.success('支付成功');
      this.invitationLetterDialog.visible = false;
    },
  },
};
</script>

<style>

</style>
